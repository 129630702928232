<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>
    <div v-if="isCheckedRegister">
      <div class="page">
        <div v-if="getModuleConfig('terms_page.step_section.enable_step_section')" class="p-3">
          <SharedStep :steps="getModuleConfig('terms_page.step_section.steps')" />
        </div>

        <div v-if="getModuleConfig('terms_page.form_title')" class="page__title">{{ getModuleConfig("terms_page.form_title") }}</div>
        <div v-if="getModuleConfig('terms_page.form_desc')" class="page__desc">
          {{ getModuleConfig("terms_page.form_desc") }}
        </div>

        <div
          v-for="(term, index) in terms"
          :key="index"
          :class="index !== (terms.length - 1) ? 's-mb-8' : ''"
        >
          <Accordion :title="term.title">
            <div v-html="term.content"></div>
          </Accordion>
          <Checkbox v-model="checked[index]" :name="'checkbox' + index">
            {{ checkboxWording }}
          </Checkbox>
        </div>
      </div>

      <div class="page__button">
        <Button
          class="s-btn-bg-primary"
          :disabled="!isAllChecked()"
          @click="goNextPage"
          >送出</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Page/Liff/Shared/Checkbox.vue";
import Accordion from "@/components/Page/Liff/Shared/Accordion.vue";
import Button from "@/components/Page/Liff/Shared/Button.vue";
import axios from 'axios';
import registerMixin from "@/mixins/liff/register";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedStep from "@/components/Page/Liff/Shared/Steps";

export default {
  mixins: [registerMixin],
  components: {
    Checkbox,
    Accordion,
    Button,
    Loading,
    SharedStep
  },
  data() {
    return {
      isCheckedRegister: false,
      checked1: false,
      checked2: false,
      terms: [],
      checked: [],
      displayLoading: false
    };
  },
  computed: {
    checkboxWording() {
      return this.getModuleConfig('terms_page.terms_check') || '已詳閱使用須知並同意相關使用說明';
    }
  },
  created() {
    this.init()
  },
  beforeMount() {
    this.fetchTerms();
  },
  methods: {
    async init () {
      // Check registered
      this.displayLoading = true
      let result = await this.checkRegistered()

      if (result === false) {
        return;
      }

      if (!(this.getModuleConfig('terms_page.enable') ?? false)) {
        this.goNextPage()
      } else {
        this.displayLoading = false;
        this.isCheckedRegister = true;
      }
    },
    async fetchTerms() {
      let terms = this.getModuleConfig("terms_page.content") ?? [];
      terms = terms.sort((a, b) => a.order - b.order);

      let checked = [];

      for (let i = 0; i < terms.length; i++) {
        terms[i].content = terms[i].content_text || (await axios.get(terms[i].content_url)).data;
        checked[i] = false;
      }

      this.terms = terms;
      this.checked = checked;
    },
    isAllChecked() {
      return this.checked.every((c) => c === true);
    },
    goNextPage() {
      localStorage.setItem("register.checkTime", Date.now());

      if (this.getModuleConfig('verify_page.enable') ?? false) {
        this.$router.push({
          name: "LiffRegisterVerifyForm",
          params: {
            orgCode: this.$route.params.orgCode,
          },
          query: this.$route.query,
        });
      } else {
        this.$router.push({
          name: "LiffRegisterMember",
          params: {
            orgCode: this.$route.params.orgCode,
          },
          query: this.$route.query,
        });
      }
    },
  },
};
</script>
